import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  useIntl,
} from "react-intl";
import st from "core/shared-translations";
import classes from "./signin-security.scss";
import GoogleMap from "containers/google-map-container";
import DeviceDetail from "components/device-detail-view";
import environment from "lib/environment";
import { AnchorIntl } from "components/intl";
import BoxedContainer from "components/boxed-container-view";
import { VisibilityOnOutlinedIcon, DevicesOutlinedIcon } from "@getgo/chameleon-icons/react";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import { Button } from '@getgo/chameleon-web-react-wrapper';
import ResponsiveDialog from 'components/dialog-view.js';

const googleMapsApiKey = environment.get().keys.googleMapsAPI;
const devicesInformationUrl = environment.get().devicesInformation_url;

const t = defineMessages({
  "devices-editor.devices": {
    id: "devices-editor.devices",
    defaultMessage: "Devices",
    description: "Title string for the devices editor section",
  },
  "devices-editor.currently-signedinon-label": {
    id: "devices-editor.currently-signedinon-label",
    defaultMessage: "Currently signed in on:",
    description: "Label for the user's currently logged in device",
  },
  "devices-editor.current-device": {
    id: "devices-editor.current-device",
    defaultMessage: "Current device:",
    description: "Label for the user's currently logged in device in modal",
  },
  "devices-editor.more-devices-list-link": {
    id: "devices-editor.more-devices-list-link",
    defaultMessage: "Review",
    description: "Link verbiage that opens the Devices modal editor",
  },
  "devices-editor.only-one-device-message": {
    id: "devices-editor.only-one-device-message",
    defaultMessage:
      "No other device have signed in the last 90 days, or since your last password reset.",
    description:
      "Static message indicating that the user only used one device, therefore, no editor is to be displayed",
  },
  "devices-editor.review-devices": {
    id: "devices-editor.review-devices",
    defaultMessage: "Review Devices",
    description: "Title for the main Review Devices modal dialog",
  },
  "devices-editor.trust-this-device-title": {
    id: "devices-editor.trust-this-device-title",
    defaultMessage: "Trust This Device",
    description:
      "Title string for the content that displays when the user clicks the 'Trust this device' menu option",
  },
  "devices-editor.trust-this-device-message": {
    id: "devices-editor.trust-this-device-message",
    defaultMessage:
      "Sign-in attempts from this device will not require email verification. Do you want to continue?",
    description:
      "Message string for the content that displays when the user clicks the 'Trust this device' menu option",
  },
  "devices-editor.unknown-device": {
    id: "devices-editor.unknown-device",
    defaultMessage: "Unknown device",
    description: "Message string to display when the device type is unknown",
  },
  "devices-editor.device": {
    id: "devices-editor.device",
    defaultMessage: "{device}",
    description: "Message string into which the device os will be populated",
  },
  "devices-editor.type-mobile": {
    id: "devices-editor.type-mobile",
    defaultMessage: "Mobile",
    description: "String shown when the device used is a mobile device",
  },
  "devices-editor.type-desktop": {
    id: "devices-editor.type-desktop",
    defaultMessage: "Desktop",
    description: "String shown when the device used is a desktop device",
  },
  "devices-editor.os": {
    id: "devices-editor.os",
    defaultMessage: "{os}",
    description: "Message string into which the device os will be populated",
  },
  "devices-editor.unknown-browser": {
    id: "devices-editor.unknown-browser",
    defaultMessage: "(unknown browser)",
    description: "Message string to display when the browser is unknown",
  },
  "devices-editor.browser": {
    id: "devices-editor.browser",
    defaultMessage: "{browser}",
    description: "Message string into which the browser type will be populated",
  },
  "devices-editor.location": {
    id: "devices-editor.location",
    defaultMessage: "{city}, {region}, {country}",
    description:
      "Label string for displaying the logged-in device's location (city, region, and country)",
  },
  "devices-editor.location-no-region": {
    id: "devices-editor.location-no-region",
    defaultMessage: "{city}, {country}",
    description:
      "Label string for displaying the logged-in device's location (city, and country)",
  },
  "devices-editor.unknown-location": {
    id: "devices-editor.unknown-location",
    defaultMessage: "Location unknown",
    description:
      "Label string to display if no location information is available",
  },
  "devices-editor.unknown-city": {
    id: "devices-editor.unknown-city",
    defaultMessage: "unknown city-",
    description: "Message string to display when the city is unknown",
  },
  "devices-editor.city": {
    id: "devices-editor.city",
    defaultMessage: "{city}",
    description: "Message string into which the city will be populated",
  },
  "devices-editor.unknown-region": {
    id: "devices-editor.unknown-region",
    defaultMessage: "-unknown state-",
    description:
      "Message string to display when the region (state in the USA, or region elsewhere) is not known",
  },
  "devices-editor.region": {
    id: "devices-editor.region",
    defaultMessage: "{region}",
    description: "Message string into which the region will be populated",
  },
  "devices-editor.unknown-country": {
    id: "devices-editor.unknown-country",
    defaultMessage: "-unknown country-",
    description: "Message string to display when the country is unknown",
  },
  "devices-editor.country": {
    id: "devices-editor.country",
    defaultMessage: "{country}",
    description: "Message string into which the country will be populated",
  },
  "devices-editor.unknown-lastSeen": {
    id: "devices-editor.unknown-lastSeen",
    defaultMessage: "Last access date and time unknown",
    description:
      "Message string to display when the last seen value is unknown",
  },
  "devices-editor.lastSeen": {
    id: "devices-editor.lastSeen",
    defaultMessage: "{lastSeen}",
    description:
      "Message string into which the last seen date and time will be populated",
  },
  "devices-editor.os-type-browser": {
    id: "devices-editor.os-type-browser",
    defaultMessage: "{os}, {deviceType}, {browser}",
    description:
      "Label string for displaying the Operating System and Browser used by the device",
  },
  "devices-editor.os-type-browser-unknown": {
    id: "devices-editor.os-type-browser-unknown",
    defaultMessage: "Device information is unknown",
    description:
      "Label string to display when the device has no OS, Device Type, or Browser information",
  },
  "devices-editor.os-and-browser-known": {
    id: "devices-editor.os-and-browser-known",
    defaultMessage: "{os}, {browser}",
    description: "Label string for displaying the Operating System and browser",
  },
  "devices-editor.unrecognized-device-title": {
    id: "devices-editor.unrecognized-device-title",
    defaultMessage: "Unrecognized Device",
    description: "Title string for the unrecognized device modal dialog",
  },
  "devices-editor.unrecognized-device-text": {
    id: "devices-editor.unrecognized-device-text",
    defaultMessage:
      "Unrecognized devices are a security risk. When you report this device, you will be signed out immediately and sent instructions to reset your password to secure your account.",
    description: "Message string for the unrecognized device modal dialog",
  },
  "devices-editor.unrecognized-device-location": {
    id: "devices-editor.unrecognized-device-location",
    defaultMessage: "Location: <b>{location}</b>",
    description:
      "Unrecognized device modal location label and value placeholder",
  },
  "devices-editor.unrecognized-device-date": {
    id: "devices-editor.unrecognized-device-date",
    defaultMessage: "Date: <b>{date}</b>",
    description: "Unrecognized device modal date label and value placeholder",
  },
  "devices-editor.unrecognized-device-device": {
    id: "devices-editor.unrecognized-device-device",
    defaultMessage: "Device: </b>{device}</b>",
    description: "Unrecognized device modal device label and value placeholder",
  },
  "devices-editor.unrecognized-device-ip-address": {
    id: "devices-editor.unrecognized-device-ip-address",
    defaultMessage: "IP Address: <b>{ip}</b>",
    description:
      "Unrecognized device modal IP Address label and value placeholder",
  },
  "devices-editor.unknown-value": {
    id: "devices-editor.unknown-value",
    defaultMessage: "unknown",
    description:
      "Text to display for a value of a device setting not specified.",
  },
  "devices-editor.unrecognized-device-report-button": {
    id: "devices-editor.unrecognized-device-report-button",
    defaultMessage: "Report & Sign Out",
    description: "Unrecognized device modal report device button label",
  },
  "devices-editor.reset-your-password-modal-title": {
    id: "devices-editor.reset-your-password-modal-title",
    defaultMessage: "Reset your password",
    description: "Reset your password modal title",
  },
  "devices-editor.reset-your-password-modal-text": {
    id: "devices-editor.reset-your-password-modal-text",
    defaultMessage:
      "Thank you for reporting the device.<br /><br />Follow the password-reset instructions emailed to <b>{email}</b> to safely sign back into your account.<br /><br />You may close this browser window.",
    description: "Reset your password modal text",
  },
  "devices-editor.no-other-devices-text": {
    id: "devices-editor.no-other-devices-text",
    defaultMessage: "Learn more about reviewing your devices.",
    description: "Secure your account modal text",
  },
});

export const getDevicePlatformRef = (device) => {
  let retVal = "devices-editor.os-type-browser";
  if (
    (!device.os || (device.os && device.os.toLowerCase() === "unknown")) &&
    (!device.deviceType || (device.deviceType &&
      device.deviceType.toLowerCase() === "unknown" ||
      device.deviceType.toLowerCase() === "other")) &&
    (!device.browser || (device.browser && device.browser.toLowerCase() === "unknown"))
  ) {
    retVal = "devices-editor.os-type-browser-unknown";
  } else if (
    device.os &&
    device.deviceType &&
    (device.deviceType.toLowerCase() !== "unknown" ||
      device.deviceType.toLowerCase() === "other") &&
    (!device.browser || device.browser.toLowerCase() === "unknown")
  ) {
    retVal = "devices-editor.os-type-browser";
  } else if (
    device.os &&
    (!device.deviceType ||
      device.deviceType.toLowerCase() === "unknown" ||
      device.deviceType.toLowerCase() === "other") &&
    device.browser &&
    device.browser.toLowerCase() !== "unknown"
  ) {
    retVal = "devices-editor.os-and-browser-known";
  }
  return retVal;
};

export const getDeviceOs = (device) => {
  return !device.os || (device.os && device.os.toLowerCase() === "unknown")
    ? "devices-editor.unknown-device"
    : "devices-editor.os";
};

export const getDeviceType = (device) => {
  let retVal = "";
  let deviceStr = device.deviceType || "";
  if (deviceStr.length) {
    deviceStr = deviceStr.toLowerCase();
  }
  switch (deviceStr) {
    case "phone":
      retVal = "devices-editor.type-mobile";
      break;
    case "computer":
      retVal = "devices-editor.type-desktop";
      break;
    default:
      retVal = "devices-editor.unknown-device";
  }
  return retVal;
};

export const getBrowser = (device) =>
  !device.browser || device.browser.toLowerCase() === "unknown"
    ? "devices-editor.unknown-browser"
    : "devices-editor.browser";

export const getCountry = (deviceCountry) =>
  !deviceCountry || deviceCountry.toLowerCase() === "unknown"
    ? "devices-editor.unknown-country"
    : "devices-editor.country";

export const getRegion = (deviceRegion) =>
  !deviceRegion || deviceRegion.toLowerCase() === "unknown"
    ? "devices-editor.unknown-region"
    : "devices-editor.region";

export const getCity = (deviceCity) =>
  !deviceCity || deviceCity.toLowerCase() === "unknown"
    ? "devices-editor.unknown-city"
    : "devices-editor.city";

const DevicesEditor = ({
  modalIsOpen,
  closeModal,
  closeTrustDeviceModal,
  closeUnrecognizedDeviceModal,
  devicesListDisplay,
  onDeviceEditLinkClick,
  mapMarkerData,
  markerClick,
  clickedDeviceId,
  currentDeviceParams,
  onTrustDeviceMenuClick,
  onTrustDeviceButtonClick,
  onUnrecognizedDeviceMenuClick,
  onReportDeviceButtonClick,
  onDeviceNameClick,
  trustedDeviceControlsClass,
  reviewDevicesContentClass,
  unrecognizedDeviceControlsClass,
  devicesModalContentClass,
  secureYourAccountModalContentClass,
  locale,
  selectedDevice,
  userEmail,
  selectedDeviceTitle,
  togglePopoverView,
  popoverIsOpen,
  popoverOpendDeviceId
}) => {
  const intl = useIntl();

  // Setting dynamic values like titles, acion buttons, onClose function etc to avoid repeating the dialog code for different states
  let modalDynamicInfo = {
    showReviewDeviceList: true,
    title: <FormattedMessage {...t['devices-editor.review-devices']} />,
    actions: false,
    onClosed: closeModal,
    closable: true,
    zindex: 100,
  };
  if (!trustedDeviceControlsClass) {
    modalDynamicInfo.title = <FormattedMessage {...t['devices-editor.trust-this-device-title']} />;
    modalDynamicInfo.onClosed = closeTrustDeviceModal;
    modalDynamicInfo.showReviewDeviceList = false;
    modalDynamicInfo.actions = <Fragment>
      <Button
        id="trust-device-button"
        onClick={onTrustDeviceButtonClick}
      >
        {intl.formatMessage(st['shared.continue'])}
      </Button>
      <Button
        id="trust-device-cancel-button"
        onClick={closeTrustDeviceModal}
        variant="tertiary">
        {intl.formatMessage(st['shared.cancel'])}
      </Button>
    </Fragment>;
  }


  else if (!secureYourAccountModalContentClass) {
    modalDynamicInfo.title = <FormattedMessage {...t['devices-editor.reset-your-password-modal-title']} />;
    modalDynamicInfo.actions = false;
    modalDynamicInfo.showReviewDeviceList = false;
    modalDynamicInfo.closable = false;
  }

  else if (!unrecognizedDeviceControlsClass) {
    modalDynamicInfo.title = <FormattedMessage {...t['devices-editor.unrecognized-device-title']} />;
    modalDynamicInfo.onClosed = closeUnrecognizedDeviceModal;
    modalDynamicInfo.showReviewDeviceList = false;
    modalDynamicInfo.actions = <Fragment>
      <Button
        id="report-device-button"
        onClick={onReportDeviceButtonClick}
      >
        {intl.formatMessage(t['devices-editor.unrecognized-device-report-button'])}
      </Button>
      <Button
        id="trust-device-cancel-button"
        onClick={closeUnrecognizedDeviceModal}
        variant="tertiary">
        {intl.formatMessage(st['shared.cancel'])}
      </Button>
    </Fragment>;
  }

  return (
    <div>
      <BoxedContainer
        title={<FormattedMessage {...t["devices-editor.devices"]} />}
        buttonIcon={<VisibilityOnOutlinedIcon />}
        buttonText={
          <FormattedMessage {...t["devices-editor.more-devices-list-link"]} />
        }
        onClick={onDeviceEditLinkClick}
        boxIcon={<DevicesOutlinedIcon color="#000000" />}
      >
        <Typography variant="caption-medium" color="type-color-secondary">
          <FormattedMessage
            {...t["devices-editor.currently-signedinon-label"]}
          />
        </Typography>
        <Typography variant="body-medium">
          <FormattedMessage
            {...t[getDevicePlatformRef(currentDeviceParams)]}
            values={{
              os: intl.formatMessage(t[getDeviceOs(currentDeviceParams)], { os: currentDeviceParams.os || "" }),
              deviceType: intl.formatMessage(t[getDeviceType(currentDeviceParams)], { deviceType: currentDeviceParams.deviceType || "" }),
              browser: intl.formatMessage(t[getBrowser(currentDeviceParams)], { browser: currentDeviceParams.browser || "" }),
            }} />
        </Typography>
        <Typography variant="body-small">
          <FormattedMessage
            {...t[currentDeviceParams.locationResourceId]}
            values={{
              city: intl.formatMessage(t[getCity(currentDeviceParams.city)], { city: currentDeviceParams.city || '' }),
              region: intl.formatMessage(t[getRegion(currentDeviceParams.region)], { region: currentDeviceParams.region || '' }),
              country: intl.formatMessage(t[getCountry(currentDeviceParams.country)], { country: currentDeviceParams.country || '' }),
            }} />
        </Typography>
      </BoxedContainer>
      <ResponsiveDialog
        id="EditDevicesModal"
        open={modalIsOpen}
        closable={modalDynamicInfo.closable}
        onClosed={modalDynamicInfo.onClosed}
        title={modalDynamicInfo.title}
        actions={modalDynamicInfo.actions}
      >
        {modalDynamicInfo && !!modalDynamicInfo.showReviewDeviceList && <div className={classes[reviewDevicesContentClass]}>
          <div id="GoogleMap-container" className={classes['devicesMap']}>
            {mapMarkerData.forEach((device) => (
              device.title = intl.formatMessage(t[getDevicePlatformRef(device)],
                {
                  os: intl.formatMessage(t[getDeviceOs(device)], { os: device.os}),
                  deviceType: intl.formatMessage(t[getDeviceType(device)], { deviceType: device.deviceType }),
                  browser: intl.formatMessage(t[getBrowser(device)], { browser: device.browser }),
                })
            ))}
            <GoogleMap
              id={googleMapsApiKey}
              language={locale}
              containerStyle={{
                position: 'relative',
                width: '100%',
                height: '200px'
              }}
              mapTypeControl={false}
              streetViewControl={false}
              fullscreenControl={false}
              disableDefaultUI
              zoomControl
              mapMarkerData={mapMarkerData}
              markerClick={markerClick}
              clickedDeviceId={clickedDeviceId} />
          </div>
          <div id="deviceList" className={classes['deviceLocations']}>
            {devicesListDisplay.forEach((device) => (
              device.title = intl.formatMessage(t[getDevicePlatformRef(device)],
                {
                  os: intl.formatMessage(t[getDeviceOs(device)], { os: device.os}),
                  deviceType: intl.formatMessage(t[getDeviceType(device)], { deviceType: device.deviceType }),
                  browser: intl.formatMessage(t[getBrowser(device)], { browser: device.browser }),
                })
            ))}
            {devicesListDisplay.forEach((device) => (
              device.location = intl.formatMessage(t[device.locationResourceId],
                {
                  city: intl.formatMessage(t[getCity(device.city)], { city: device.city }),
                  region: intl.formatMessage(t[getRegion(device.region)], { region: device.region }),
                  country: intl.formatMessage(t[getCountry(device.country)], { country: device.country }),
                })
            ))}
            {devicesListDisplay.map((device, index) => (
              <DeviceDetail
                key={`Device-${index}`}
                device={device}
                onTrustDeviceMenuClick={onTrustDeviceMenuClick}
                onUnrecognizedDeviceMenuClick={onUnrecognizedDeviceMenuClick}
                onDeviceNameClick={onDeviceNameClick}
                togglePopoverView={togglePopoverView}
                popoverIsOpen={popoverIsOpen}
                popoverOpendDeviceId={popoverOpendDeviceId}
                clickedDeviceId={clickedDeviceId} />
            ))}
            <div id="devices_list_end" className={classes['disclaimer']}>
              <Typography variant="body-small" color="type-color-brand-default">
                <AnchorIntl
                  a={{
                    href: devicesInformationUrl,
                    message: t['devices-editor.no-other-devices-text'],
                    target: '_blank',
                    includeRel: true,
                  }}
                />
              </Typography>
            </div>
          </div>
        </div>}

        <div id="trustDeviceModal" className={`${classes[trustedDeviceControlsClass]}`}>
          <Typography variant="body-medium">
            <FormattedMessage {...t['devices-editor.trust-this-device-message']} />
          </Typography>
          <Typography variant="body-medium-strong" className={classes["lastLineConclusion"]}>
            {selectedDeviceTitle}
          </Typography>
        </div>

        <div id="unrecognizedDeviceModal" className={classes[unrecognizedDeviceControlsClass]}>
          <Typography variant="body-medium">
            <FormattedHTMLMessage
              {...t['devices-editor.unrecognized-device-location']}
              values={{ location: selectedDevice.location }} />
          </Typography>
          <Typography variant="body-medium">
            <FormattedHTMLMessage
              {...t['devices-editor.unrecognized-device-date']}
              values={{ date: selectedDevice.timeOfLastLogin }} />
          </Typography>
          <Typography variant="body-medium">
            <FormattedHTMLMessage
              {...t['devices-editor.unrecognized-device-device']}
              values={{
                device: intl.formatMessage(t[getDevicePlatformRef(selectedDevice)],
                  {
                    os: intl.formatMessage(t[getDeviceOs(selectedDevice)],
                      { os: selectedDevice.os || intl.formatMessage(t['devices-editor.unknown-value']) }),
                    deviceType: intl.formatMessage(t[getDeviceType(selectedDevice)],
                      { deviceType: selectedDevice.deviceType || intl.formatMessage(t['devices-editor.unknown-value']) }),
                    browser: intl.formatMessage(t[getBrowser(selectedDevice)],
                      { browser: selectedDevice.browser || intl.formatMessage(t['devices-editor.unknown-value']) }),
                  })
              }} />
          </Typography>
          <Typography variant="body-medium">
            <FormattedHTMLMessage
              {...t['devices-editor.unrecognized-device-ip-address']}
              values={{ ip: selectedDevice.ip || intl.formatMessage(t['devices-editor.unknown-value']) }} />
          </Typography>
          <Typography variant="body-medium" className={classes["lastLineConclusion"]}>
            <FormattedMessage {...t['devices-editor.unrecognized-device-text']} />
          </Typography>
        </div>

        <div id="secureYourAccountModal" className={classes[secureYourAccountModalContentClass]}>
          <Typography variant="body-medium">
            <FormattedHTMLMessage
              {...t['devices-editor.reset-your-password-modal-text']}
              values={{ email: userEmail }} />
          </Typography>
        </div>
      </ResponsiveDialog>
    </div>
  );
};

DevicesEditor.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeTrustDeviceModal: PropTypes.func.isRequired,
  closeUnrecognizedDeviceModal: PropTypes.func.isRequired,
  devicesListDisplay: PropTypes.array.isRequired,
  onDeviceEditLinkClick: PropTypes.func.isRequired,
  mapMarkerData: PropTypes.array.isRequired,
  markerClick: PropTypes.func.isRequired,
  clickedDeviceId: PropTypes.string,
  currentDeviceParams: PropTypes.object.isRequired,
  onTrustDeviceMenuClick: PropTypes.func.isRequired,
  onTrustDeviceButtonClick: PropTypes.func.isRequired,
  onUnrecognizedDeviceMenuClick: PropTypes.func.isRequired,
  onReportDeviceButtonClick: PropTypes.func.isRequired,
  onDeviceNameClick: PropTypes.func.isRequired,
  trustedDeviceControlsClass: PropTypes.string.isRequired,
  reviewDevicesContentClass: PropTypes.string.isRequired,
  devicesModalContentClass: PropTypes.string.isRequired,
  secureYourAccountModalContentClass: PropTypes.string.isRequired,
  unrecognizedDeviceControlsClass: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  selectedDevice: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
  selectedDeviceTitle: PropTypes.string.isRequired,
  togglePopoverView: PropTypes.func.isRequired,
  popoverIsOpen: PropTypes.bool.isRequired,
  popoverOpendDeviceId: PropTypes.string.isRequired,


};

export default DevicesEditor;
