import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from "lib/environment";

const apiBaseUrl = { baseURL: environment.get().api.castleServiceURL };
const devicesEndpoint = appconfig.endpoints.devices;

export const daoDevicesGet = (cid) => api.get(`${devicesEndpoint}?requestToken=${cid}`, apiBaseUrl);
export const daoApproveDevice = (token) => api.put(`${devicesEndpoint}/${token}/trust`, apiBaseUrl);
export const daoReportDevice = (token) => api.put(`${devicesEndpoint}/${token}/block`, apiBaseUrl);
